import { useEffect } from 'react';
import { useState } from 'react';
import { ethers } from 'ethers';
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import ABI from './components/abi/ABI.json';
import abiPAnft from './components/abi/abiPAnft.json';
import NFTContainer from './containers/NFTContainer/NFTContainer';
import StakedNFTContainer from './containers/StakedNFTContainer/StakedNFTContainer'

import './App.css';

function App() {

    const [walletAddress, setWalletAddress] = useState(null);
    const [nfts, setNfts] = useState([]);
    const [stakedNfts, setStakedNfts] = useState([]);
    //Use effect for the state of getNftData
    useEffect(() => {
        getNftData()
    }, [walletAddress]
    )

    /*
     *   W A L L E T   F U N C T I O N S
     */

    // @notice: wallet connection
    const connectWallet = async () => {
        //If not signed in to Metamask, do so
        if (typeof window.ethereum !== 'undefined') {

            //Get the accounts from Metamask

            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

            //Set the wallet address to the first account in the wallet
            setWalletAddress(accounts[0])

            //Set the provider
            const provider = new ethers.providers.Web3Provider(window.ethereum)

            //Get a new instance of the pluto alliance NFT contract

            const plutoAllianceContract = new ethers.Contract('0xDfe3AC769b2d8E382cB86143E0b0B497E1ED5447', abiPAnft, provider.getSigner())
            const isApproved = await plutoAllianceContract.isApprovedForAll(accounts[0], '0x882eA36F2031F3EDD6Cc243472f6Bea7195ECaf3')

            //If the user is already approved, disable the approve button
            if (isApproved) {
                disableButton('approveButton')
            }
        }
    }


    const getNftData = async () => {
        if (!walletAddress) return;

        //      setConnectButtonText();

        //Fetch options for the opensea query
        const options = {method: 'GET', headers: {Accept: 'application/json', 'X-API-KEY':'47ecc73b776649359b932b67e0c0100e'}}; 

        //User wallet nft data from opensea
        const openseaOwnerUrl = 'https://api.opensea.io/api/v1/assets?owner=' + walletAddress +              // User's wallet address
                           '&asset_contract_address=0xdfe3ac769b2d8e382cb86143e0b0b497e1ed5447&limit=50';  // Filter by the Pluto Alliance address
        const userResponse = await fetch(openseaOwnerUrl, options)
                                   .then ((response) => {
                                       if (response.ok) {
                                           return response;
                                       }
                                   })
                                   .catch((error => {
                                       if (error == '429') {
                                           alert('Error 429: Too many requests!\n\nWait a few seconds before trying to query again.');
                                       } else {
                                           alert('Error ' + error.toString() + "!");
                                       }
                                   }));
        const userData = await userResponse.json();

        //Add the nft data of the owner to a new array to pass to the UI method
        const walletNftData = []
        for (let i = 0; i < userData.assets.length; i++) {
            walletNftData.push(userData.assets[i]);
        }

        //**Getting staked nft data
        //Set the provider 
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        //Get an instance of the staking contract
        const stakingContract = new ethers.Contract('0x882eA36F2031F3EDD6Cc243472f6Bea7195ECaf3', ABI, provider.getSigner())
        //Get the IDs for the tokens staked by the owner of this wallet (see contract documentation)       
        const stakedNftHexIds = await stakingContract.getStakedTokens(walletAddress);

        //^ Make sure this comes back as an array of strings and not as hex numbers
        //If they are hexes, use this:     Also replace stakedIds with stakedNftHexIds on line 96
        const stakedIds = [];
        for (let j = 0; j < stakedNftHexIds.length; j++) {
          const num = ethers.BigNumber.from(stakedNftHexIds[j]).toString();
          stakedIds.push(num);
        }
        
        //Search through the nfts owned by the contract, and if one of the staked nfts is in that list, add it to the list of staked nft data.
        const stakedNftData = [];
        if (stakedIds.length > 0) {
            //Set up the url for the opensea query of the staking contract
            var contractOpenseaUrl = 'https://api.opensea.io/api/v1/assets?owner=0x882eA36F2031F3EDD6Cc243472f6Bea7195ECaf3'
            //Gets tricky here. For each id of the nft in the stakedIds list, add it to the search string so it call pull the right data in one go
            for (let k = 0; k < stakedIds.length; k++) {
                contractOpenseaUrl += ('&token_ids=' + stakedIds[k]);
            }
            const finalContractUrl = contractOpenseaUrl + '&limit=50';

            //Fetch the data for the nfts that the user at the current wallet address has already staked
            const contractResponse = await fetch(finalContractUrl, options)
                                        .then ((response) => {
                                                    if (response.ok) {
                                                        return response;
                                                    }
                                            })
                                            .catch((error => {
                                                    if (error == '429') {
                                                        alert('Error 429: Too many requests!\n\nWait a few seconds before trying to query again.');
                                                    } else {
                                                        alert('Error ' + error.toString() + "!");
                                                    }
                                                    
                                            }));
            const contractNftData = await contractResponse.json();

            //Add the nft data from the staking contract to an array to send to the UI method
          
            for (let n = 0; n < contractNftData.assets.length; n++) {
                stakedNftData.push(contractNftData.assets[n]);
            }
        }

        //Finally, set up the UI for staked and unstaked nfts
        setUpUI(walletNftData, stakedNftData);
    }

    /*
     *   C O N T R A C T   C A L L   F U N C T I O N S
     */

    //@notice: Approves a staking contract to stake any NFTs in the collection (** doesn't stake anything yet!)
    const approval = async () => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)

        //Calls the chain to set the approval of the staking contract for the test nfts in the user's wallet

        const plutoAllianceContract = new ethers.Contract('0xDfe3AC769b2d8E382cB86143E0b0B497E1ED5447', abiPAnft, provider.getSigner())
        const result = await plutoAllianceContract.setApprovalForAll('0x882eA36F2031F3EDD6Cc243472f6Bea7195ECaf3', true)

    }

    const unapproval = async () => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)

        //Calls the chain to unapprove of the staking contract for the test nfts in the user's wallet
        const plutoAllianceContract = new ethers.Contract('0xDfe3AC769b2d8E382cB86143E0b0B497E1ED5447', abiPAnft, provider.getSigner())
        const result = await plutoAllianceContract.setApprovalForAll('0x882eA36F2031F3EDD6Cc243472f6Bea7195ECaf3', false)

    }

    //@notice: Stake an NFT
    const stakeNft = async () => {

        const nftCards = document.getElementsByClassName("card nft-card")
        const idsFromCards = []
        const idsFromNftData = []

        //Iterate through the card objects and get the ID number from all the ones that are selected.
        for (let i = 0; i < nftCards.length; i++) {

            if (nftCards[i].hasAttribute('toggle')) {
                //Add to the ids from card
                const nftCardText = nftCards[i].textContent;
                const textArray = nftCardText.split("#");   //Splits on the '#' - that's how they're stored in the NFTCard
                const tokenIdFromCard = textArray[textArray.length - 1];
                idsFromCards.push(tokenIdFromCard);
            }
        }

        if (idsFromCards.length < 1) {
            alert("No NFTs selected! Select one or more to stake, then try again.");
            return;
        }

        //Get the provider 
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        //Get an instance of the staking contract
        const stakingContract = new ethers.Contract('0x882eA36F2031F3EDD6Cc243472f6Bea7195ECaf3', ABI, provider.getSigner());

        //Call whatever function you need to on the staking contract to initiate a staking transfer
        const result = await stakingContract.multiStakeToken('0xDfe3AC769b2d8E382cB86143E0b0B497E1ED5447', idsFromCards);

        if (result != null) {
            alert('\nTransaction pending!\n\nRefresh the page after it completes to see your updated collection.');
        }
    }

    //@notice: Unstakes an NFT
    const unstakeNft = async () => {

        const stakedNftCards = document.getElementsByClassName("card staked-nft-card");
        const idsFromCards = [];

        //Iterate through the card objects and get the ID number from all the ones that are selected.
        for (let i = 0; i < stakedNftCards.length; i++) {

            if (stakedNftCards[i].hasAttribute('toggle')) {
                //Add to the ids from card
                const cardText = stakedNftCards[i].textContent;
                const textArray = cardText.split("#");   //Will split on the '#' when returning to rarible - that's how they're stored in the metadata
                const tokenIdFromCard = textArray[textArray.length - 1];
                idsFromCards.push(tokenIdFromCard);
            }
        }

        if (idsFromCards.length < 1) {
            alert("No NFTs selected! Select one or more to unstake, then try again.");
            return;
        }

        //Get the provider  
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        //Get an instance of the staking contract
        const stakingContract = new ethers.Contract('0x882eA36F2031F3EDD6Cc243472f6Bea7195ECaf3', ABI, provider.getSigner());

        //Call whatever function you need to on the staking contract to initiate a cancel staking transfer 
        const result = await stakingContract.cancelMultiStakes(idsFromCards);

        if (result != null) {
            alert('\nTransaction pending!\n\nRefresh the page after it completes to see your updated collection.');
        }
    }


    /*
     *    U I   F U N C T I O N S
     */

    const setUpUI = async (unstakedNftData, stakedNftData) => {

        //Sets the UI for staked NFTs
        await setStakedNfts(stakedNftData);

        //Sets the UI for non-staked NFTs
        await setNfts(unstakedNftData);

        //Set up each of the NFT card objects with an on-click listener for selecting/not selecting them
        const NFTCards = document.getElementsByClassName("card nft-card");
        for (let i = 0; i < NFTCards.length; i++) {
            NFTCards[i].addEventListener("click", function () { selectOrDeselectNft(NFTCards[i]) });
        }

        const stakedNftCards = document.getElementsByClassName("card staked-nft-card");
        for (let i = 0; i < stakedNftCards.length; i++) {
            stakedNftCards[i].addEventListener("click", function () { selectOrDeselectNft(stakedNftCards[i]) });
        }
    }

    const setConnectButtonText = () => {
        const connectButton = document.getElementsByClassName('connect-button');

        const front = walletAddress.substr(0, 4);
        const back = walletAddress.substr(walletAddress.length - 4, walletAddress.length);

        const shortString = front + "..." + back;

        connectButton.textContent = shortString;
    }

    //  Sets up a toggle element for the card that changes its style on click

    const selectOrDeselectNft = (nftCard) => {
        if (!nftCard.hasAttribute('toggle')) {
            nftCard.setAttribute('toggle', 'true')
            nftCard.style.backgroundColor = 'limegreen'
        } else {
            nftCard.removeAttribute('toggle')
            nftCard.style.backgroundColor = 'rgba(0, 160, 152, 0.0)'
        }
    }

    //Helper function to disable different buttons (pass in the string id of the button)
    const disableButton = (whichButton) => {
        const button = document.getElementById(whichButton)
        button.style.backgroundColor = 'darkgray'
        button.style.color = 'lightslategray'
        button.disabled = 'true';

        if (whichButton === 'approveButton') {
            button.style.backgroundColor = 'lightgreen'
            button.style.color = 'white'
            button.textContent = 'Approved!'
        }

        console.log(whichButton + " disabled")
    }

    //Helper function to enable buttons
    const enableButton = (whichButton) => {
        const button = document.getElementById(whichButton)
        button.style.backgroundColor = 'deepskyblue'
        button.style.color = 'white'
        button.disabled = 'false';
        console.log(whichButton + " enabled")
    }

    /*
     *   H T M L   E L E M E N T S
     */

    return (
        <div className="App">
            <div className="container">
                <div className="nav-container">
                    <button className='connect-button' onClick={connectWallet}>
                        Connect Wallet
                    </button>
                </div>
                <div className='wallet-text'>
                    Account: {walletAddress}
                </div>
                <div className='text'>
                    Staked Pluto Alliance Aliens
                    </div>
                <div className='NFTStakedBox'>
                    <StakedNFTContainer stakedNfts={stakedNfts} />
                </div>
                <div className='button-container'>
                    <button id='approveButton' className='approve-button' onClick={approval}>
                        Approve
                        </button>
                    <button id='stakeButton' className='stake-button' onClick={stakeNft}>
                        Stake
                        </button>
                    <button id='unstakeButton' className='unstake-button' onClick={unstakeNft}>
                        Unstake
                        </button>
                </div>
                <NFTContainer nfts={nfts} />
                <div className='footer' >
                    Made by &nbsp;<a href="https://t.me/+UBxEce4OUVAeoTOn" rel="noreferrer">
                        Nerd DAO
                </a>
                </div>
            </div>
        </div>
    );
}
export default App;