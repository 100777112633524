import React from 'react'

const NFTCard = ({ nft }) => {
    //Function to tell this what to do on button click

    return (
        <div className='card nft-card' >
            <img src={nft.image_original_url} className='nft-image' alt='the NFT' />
            <div className='card content'>
                <div className='card content-item'>
                   {nft.name}
                </div>
            </div>
        </div>
    )
}

export default NFTCard
