import React from 'react'
import StakedNFTCard from '../../components/StakedNFTCard/StakedNFTCard'

const StakedNFTContainer = ({ stakedNfts }) => {
 
    return (
        <div className='staked-nft-container'>
            {stakedNfts.map((stakedNft, index) => {
                return <StakedNFTCard stakedNft={stakedNft} key={index} />
            })}
        </div>
    )
}

export default StakedNFTContainer
