import React from 'react'

const StakedNFTCard = ({ stakedNft }) => {


    return (
        <div className='card staked-nft-card' >
            <img src={stakedNft.image_original_url} className='nft-image' alt='the NFT' />
            <div className='card content'>
                <div className='card content-item'>
                    {stakedNft.name}
                </div>
            </div>
        </div>
    )
}

export default StakedNFTCard
